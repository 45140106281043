import axios from "axios";
import Swal from "sweetalert2";
import { tokenUser } from "@/features/global";

export const personalData = {
  async storeUpdatePersonalData(data) {
    try {
      const formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      const response = await axios.post(`/clientUpdateProfile`, formData, {
        headers: {
          Authorization: `Bearer ${tokenUser}`,
        },
      });

      Swal.fire({
        title: "",
        text: response.data.message,
        icon: "success",
      });

      return response; // Return the response for further use
    } catch (e) {
      Swal.fire({
        title: "",
        text: e.response?.data?.message || "An error occurred",
        icon: "error",
      });

      throw e; // Rethrow the error if needed
    }
  },
};
