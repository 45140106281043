<template>
  <section class="filter_home">
    <div class="container">
      <div class="group">
        <div class="input-group">
          <!-- country -->
          <select
            class="form-select"
            aria-label="Default select example"
            @change="fetchState($event)"
            v-model="country_id"
          >
            <option disabled selected>{{ $t("country_name") }}</option>
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.id"
            >
              {{ country.title }}
            </option>
          </select>
          <span class="icon"><i class="bi bi-geo-alt"></i></span>
          <span v-if="!country_id" class="text">{{ $t("country_name") }}</span>
          <!-- country -->
        </div>
        <div class="input-group">
          <!-- city -->
          <select
            class="form-select"
            aria-label="Default select example"
            @change="fetchDepartment($event)"
            v-model="state_id"
            :disabled="!country_id"
          >
            <option disabled selected>{{ $t("cities_name") }}</option>
            <option
              v-for="(state, index) in states"
              :key="index"
              :value="state.id"
            >
              {{ state.title }}
            </option>
          </select>
          <span class="icon"><i class="bi bi-geo-alt"></i></span>
          <span v-if="!state_id" class="text">{{ $t("department_name") }}</span>

          <!-- city -->
        </div>
        <div class="input-group">
          <!-- department -->
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="city_id"
            :disabled="!state_id"
          >
            <option selected>{{ $t("department_name") }}</option>
            <option
              v-for="(city, index) in cities"
              :key="index"
              :value="city.id"
            >
              {{ city.title }}
            </option>
          </select>
          <span class="icon"><i class="bi bi-geo-alt"></i></span>
          <span v-if="!city_id" class="text">{{ $t("cities_name") }}</span>
          <!-- department -->
        </div>
        <!-- category -->

        <!-- category -->
        <div class="button">
          <router-link
            @click="saveData"
            :to="{ name: 'Giude' }"
            class="btn first"
          >
            {{ $t("search_now") }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "filter home",

  data() {
    return {
      countries: [],
      cities: [],
      states: [],
      country_id: "",
      city_id: "",
      state_id: "",
      category: "",
      category_id: [],
      experience_years: "",
    };
  },
  computed: {
    searchButtonClass() {
      if (this.country_id) {
        return "btn-enabled";
      } else {
        return "btn-disabled";
      }
    },
  },
  methods: {
    fetch_country_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.heroData);
      });
    },
    fetchState(e) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let country = { country_id: e.target.value };
      axios.post("/fetch_states_by_country_id", country).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.states = data.data.data;
        }
      });
    },
    fetchDepartment(x) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let city = { state_id: x.target.value };
      axios.post("/fetch_cities_by_state_id", city).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.cities = data.data.data;
          // console.log(this.department);
        }
      });
    },
    saveData() {
      const data = {};
      if (this.country_id) {
        data["country_id"] = this.country_id;
      }
      if (this.state_id) {
        data["state_id"] = this.state_id;
      }
      if (this.city_id) {
        data["city_id"] = this.city_id;
      }
      if (this.category_id) {
        data["category_id"] = this.category_id;
      }
      if (this.experience_years) {
        data["experience_years"] = this.experience_years;
      }
      localStorage.setItem("myData", JSON.stringify(data));
    },
  },
  created() {
    this.fetch_country_data();
    // this.fetch_category();
  },
};
</script>

<style></style>
