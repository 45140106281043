<template>
  <div class="joinsButtons">
    <router-link
      :to="{ name: 'JoinLawer' }"
      :class="{ buttonJoin: this.$route.name == 'JoinLawer' }"
      class="w-25 btn border"
    >
      {{ $t("joinLawyer") }}
    </router-link>
    <router-link
      :to="{ name: 'JoinUser' }"
      :class="{ buttonJoin: this.$route.name == 'JoinUser' }"
      class="w-25 btn border"
    >
      {{ $t("joinUser") }}
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
