<template>
  <div class="bg-white p-3">
    <h2>{{ $t("change_pass") }}</h2>
    <form @submit.prevent="changePassword()" class="mt-3">
      <div class="input-group position-relative">
        <span
          class="eyeicon"
          v-if="!show_old_password"
          @click="show_old_password = !show_old_password"
        >
          <i class="bi bi-eye"></i>
        </span>

        <span
          class="eyeicon"
          v-else
          @click="show_old_password = !show_old_password"
        >
          <i class="bi bi-eye-slash"></i>
        </span>

        <label class="form-label"> {{ $t("old_password") }}</label>
        <input
          :type="show_old_password ? 'text' : 'password'"
          class="form-control"
          v-model="formData.old_password"
          required
        />
        <span class="icon_form">
          <i class="bi bi-lock"></i>
        </span>
      </div>
      <div class="input-group position-relative">
        <span
          class="eyeicon"
          v-if="!show_password_new"
          @click="show_password_new = !show_password_new"
        >
          <i class="bi bi-eye"></i>
        </span>

        <span
          class="eyeicon"
          v-else
          @click="show_password_new = !show_password_new"
        >
          <i class="bi bi-eye-slash"></i>
        </span>

        <label class="form-label"> {{ $t("new_password") }}</label>
        <input
          :type="show_password_new ? 'text' : 'password'"
          class="form-control"
          v-model="formData.new_password"
          required
        />
        <span class="icon_form">
          <i class="bi bi-lock"></i>
        </span>
      </div>
      <button type="submit" class="primaryBackground">{{ $t("send") }}</button>
    </form>
  </div>
</template>

<script>
import { changePassword } from "@/features/settings/changePassword";
export default {
  data() {
    return {
      show_old_password: false,
      show_password_new: false,
      formData: {
        old_password: "",
        new_password: "",
      },
    };
  },
  methods: {
    changePassword() {
      changePassword.storeUpdateChangePassword(this.formData);
    },
  },
};
</script>

<style scoped>
.eyeicon {
  position: absolute;
  top: 3rem;
  left: 2rem;
  transform: translateY(-50%);
  color: #000;
  z-index: 444;
}
</style>
