import axios from "axios";
import Swal from "sweetalert2";
export default {
  async Login({ commit }, form) {
    try {
      let response = await axios.post(
        "https://portal.snadk.net/website/lawyer_login",
        form
      );
      localStorage.setItem("token", response.data.data.api_token);
      localStorage.setItem("lawyer", JSON.stringify(response.data.data));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.data.api_token}`;
      this.$router.push("/dashboard");

      // location.reload();
      commit("setLawyer", { lawyer: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  async LoginUser({ commit }, form) {
    try {
      let response = await axios.post(
        "https://portal.snadk.net/website/clientLogin",
        form
      );
      console.log(response);

      localStorage.setItem("tokenUser", response.data.data.api_token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.data.api_token}`;
      this.$router.push("/dashboard-user");
      // location.reload();
      commit("setUser", { user: response });
      console.log(response);

      if (response.data.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          timer: 1500,
        });
      }

      return response;
    } catch (error) {
      return error.response;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
};
