<template>
  <div class="dashboard money_dashboard">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-5 col-12">
          <SidebarUser />
        </div>
        <div class="col-lg-9 col-md-7 col-12">
          <!-- <Card /> -->
          <personalDataForm  class="border" />
          
          <changePasswordForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarUser from "@/components/dashboard/user/sidebar.vue";
// import Card from "@/components/dashboard/user/money/cards.vue";
import personalDataForm from "@/components/dashboard/user/setting/personalDataForm.vue";
import changePasswordForm from "@/components/dashboard/user/setting/changePasswordForm.vue";

export default {
  components: {
    // Card,
    personalDataForm,
    changePasswordForm,
    SidebarUser,
  },
};
</script>

<style></style>
