<template>
    <!-- <div v-if="cards.done_requests.length == []"  class="row">
       <h2 class="text-center my-5 py-5" >{{ $t('no_end_card') }}</h2>
  </div> -->
    <div  class="row">
      <div class="col-lg-6 col-12" v-for="(card, index) in cards.done_requests" :key="index">
        <div class="consult_card">
          <div class="header_card">
            <div class="date">
              <span> {{ $t("consult_time") }}</span>
              <h6>{{ formatDate(card.date) }}</h6>
            </div>
            <div class="action" v-if="card.status == 2">
            <span class="wait ok"> {{ $t("interview_ok") }} </span>
          </div>
          <div class="action " v-if="card.status == 3 ">
            <span class="wait cancel"> {{ $t("interview_cancel") }} </span>
          </div>
          </div>
          <div class="content">
            <span>
              <i v-if="card.contact_type == 1" class="bi bi-camera-video"></i>
              <i v-if="card.contact_type == 2" class="bi bi-telephone"></i>
            </span>
            <div class="text">
              <h4 v-if="card.contact_type == 1">{{ $t("vedio_consultant") }}</h4>
              <h4 v-if="card.contact_type == 2">{{ $t("call_consultant") }}</h4>
              <p>{{ card.user_name }} , {{ card.lawyer_email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import moment from "moment";
  
  export default {
    data() {
      return {
        cards: {

        },
      };
    },
    methods: {
    fetch_card_data() {
     
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios.get("/yourRequests").then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  created() {
    this.fetch_card_data();
  },
  };
  </script>
  
  <style></style>
  