<template>
  <!-- <div v-if="cards.accepted_requests.length == []"  class="row">
       <h2 class="text-center my-5 py-5" >{{ $t('no_current_card') }}</h2>
  </div>  -->
  <div class="row">
    <div
      class="col-lg-6 col-12"
      v-for="(card, index) in cards.accepted_requests"
      :key="index"
    >
      <div class="consult_card">
        <div class="header_card">
          <div class="date">
            <span> {{ $t("consult_time") }}</span>
            <h6>{{ formatDate(card.date) }}</h6>
          </div>
          <div class="action">
            <span @click="finishedRequest(card.id)" class="wait">
              {{ $t("wait_inter") }}
            </span>
          </div>
        </div>
        <div class="content">
          <span>
            <i v-if="card.contact_type == 1" class="bi bi-camera-video"></i>
            <i v-if="card.contact_type == 2" class="bi bi-telephone"></i>
          </span>
          <div class="text">
            <h4 v-if="card.contact_type == 1">{{ $t("vedio_consultant") }}</h4>
            <h4 v-if="card.contact_type == 2">{{ $t("call_consultant") }}</h4>
            <p>{{ card.user_name }} , {{ card.lawyer_email }}</p>
            <h5 class="link" v-if="card.link">
              <i class="bi bi-globe"></i>
              {{ $t("link_advice") }} :
              <a :href="card.link" target="_blank">{{ $t("link") }}</a>
            </h5>
            <h5 class="link" v-else>
              <i class="bi bi-globe"></i>
              {{ $t("link_advice") }} : {{ $t("dont_add") }}
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                @click="requestId(card.id)"
              >
                {{ $t("add_link") }}
              </button>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade accept_modal"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("add_link_consult") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("plz_copy_link") }}</p>
          <form @submit.prevent="addLink()">
            <input
              type="url"
              class="form-control"
              :placeholder="$t('copy_link')"
              v-model="form.link"
            />
            <button type="submit" class="btn first">
              {{ $t("add_link") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      cards: {},
      form: {
        request_id: "",
        link: "",
      },
    };
  },
  methods: {
    requestId(request_id) {
      this.form.request_id = request_id;
    },

    fetch_card_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios.get("/yourRequests").then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    async addLink() {
      try {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        const { data } = await axios.post("/add_request_link", this.form);
        this.status = data.status;
        this.message = data.message;
        if (this.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: this.message,
            timer: 1500,
          });
          this.$router.go("/dashboard");
          // location.reload
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: this.message,
            timer: 1500,
          });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: error.response.data.message,
          timer: 1500,
        });
      }
    },

    async finishedRequest(request_id) {
      try {
        let id = { request_id: request_id };
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("tokenUser")}`;
        const { data } = await axios.post("/finish_request", id);
        this.status = data.status;
        this.message = data.message;

        if (this.status == true) {
          // this.$router.push(`/accept-consult/${request_id}`);
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: this.message,
            timer: 1500,
          });
          location.reload();
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: this.message,
            timer: 1500,
          });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: error.response.data.message,
          timer: 1500,
        });
      }
    },
  },
  created() {
    this.fetch_card_data();
  },
};
</script>

<style></style>
