<template>
  <div class="lawyer_header_second">
    <div class="main_header">
      <h2>{{ header.title }}</h2>
      <p v-html="header.text"></p>
    </div>
    <div class="img">
      <img :src="header.image" alt="" />
    </div>
  </div>
</template>

<script>
import { LawyerData } from "@/api/lawyer/header.js";

export default {
  data() {
    return {
      header: {},
    };
  },
  mounted() {
    LawyerData().then((response) => {
      this.header = response.data.data;
      return response;
    });
  },
};
</script>

<style></style>
