<template>
  <div class="bg-white p-3 rounded-3">
    <h2>{{ $t("personal_data") }}</h2>
    <form class="mt-3" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("full_name") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="formData.fullName"
              :placeholder="$t('enter_full_name')"
              required
            />
            <span class="icon_form">
              <i class="bi bi-person"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("email") }}</label>
            <input
              type="email"
              class="form-control"
              v-model="formData.email"
              :placeholder="$t('email')"
              required
            />
            <span class="icon_form">
              <i class="bi bi-envelope"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control"
              v-model="formData.phone"
              :placeholder="$t('phone')"
              required
            />
            <span class="icon_form">
              <i class="bi bi-phone"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("address") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="formData.address"
              :placeholder="$t('address')"
              required
            />
            <span class="icon_form">
              <i class="bi bi-geo-alt"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("profile_image") }}</label>
            <input
              type="file"
              class="form-control"
              accept="image/*"
              @change="handleImageChange"
            />
          </div>
          <div v-if="formData.imagePreview" class="mt-3">
            <img
              :src="formData.imagePreview"
              alt="Image Preview"
              class="img-thumbnail"
              style="max-width: 150px"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="primaryBackground">{{ $t("send") }}</button>
    </form>
  </div>
</template>

<script>
import { personalData } from "@/features/settings/personalData";
export default {
  data() {
    return {
      formData: {
        fullName: "",
        email: "",
        phone: "",
        address: "",
        image: null,
      },
      imagePreview: null,
    };
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    async handleSubmit() {
      personalData.storeUpdatePersonalData(this.formData);
    },
  },
};
</script>
