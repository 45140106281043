<template>
  <section class="join_page">
    <div class="container">
      <JoinsButtons />
      <!-- <ul class="tabs_login">
        <li class="active">
          <router-link :to="{ name: 'JoinLawer' }">
            <h3>{{ $t("joinLawyer") }}</h3>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'loginLawyer' }">
            <h3>{{ $t("login") }}</h3>
          </router-link>
        </li>
      </ul> -->
      <form>
        <!-- start first stage -->
        <div class="row" v-if="step === 1">
          <div class="text py-5">
            <h2>{{ $t("join_us") }}</h2>
            <p>{{ $t("join_desc") }}</p>
          </div>
          <div class="col-lg-7 col-12">
            <div>
              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("full_name") }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('enter_full_name')"
                  v-model="join.name"
                  @input="validateName"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-person"></i>
                </span>
              </div>
              <span class="error" v-if="errors.name">{{ errors.name }}</span>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("phones") }}
                </label>
                <input
                  type="tel"
                  class="form-control"
                  :placeholder="$t('phone_placeholder')"
                  v-model="join.phone"
                  @input="validatePhone"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-telephone"></i>
                </span>
              </div>
              <span class="error" v-if="errors.phone">{{ errors.phone }}</span>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("email") }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  :placeholder="$t('full_email')"
                  v-model="join.email"
                  @input="validateEmail"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-envelope"></i>
                </span>
              </div>
              <span class="error" v-if="errors.email">{{ errors.email }}</span>
              <!-- finish input group -->

              <!-- start input group -->

              <div class="input-group">
                <label class="form-label">
                  {{ $t("image_registration") }}
                </label>
              </div>
              <div class="input-upload-group">
                <label for="file">
                  <div v-if="!fileUploaded" class="content">
                    <span> <i class="bi bi-cloud-arrow-up"></i></span>
                    {{ $t("upload image") }}
                  </div>
                </label>
                <input
                  type="file"
                  id="file"
                  @change="handleFileUpload"
                  ref="fileInput"
                />
                <label class="inpuet_file">{{ this.fileData.name }}</label>
              </div>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group w-100">
                <label class="form-label">
                  {{ $t("adress_detials") }}
                </label>
                <div class="group row w-100">
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <!-- country -->
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        @change="fetchState($event)"
                        v-model="join.country_id"
                      >
                        <option disabled selected hidden value="">
                          {{ $t("country_name") }}
                        </option>
                        <option
                          v-for="(country, index) in countries"
                          :key="index"
                          :value="country.id"
                        >
                          {{ country.title }}
                        </option>
                      </select>
                      <span v-if="!join.country_id" class="text">{{
                        $t("country_name")
                      }}</span>
                      <!-- country -->
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <!-- state -->
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        @change="fetchDepartment($event)"
                        v-model="join.state_id"
                        :disabled="!join.country_id"
                      >
                        <option disabled selected hidden value="">
                          {{ $t("cities_name") }}
                        </option>
                        <option
                          v-for="(state, index) in states"
                          :key="index"
                          :value="state.id"
                        >
                          {{ state.title }}
                        </option>
                      </select>
                      <span v-if="!join.state_id" class="text">{{
                        $t("cities_name")
                      }}</span>
                      <!-- state -->
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <!-- city -->
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="join.city_id"
                        :disabled="!join.state_id"
                      >
                        <option disabled selected hidden value="">
                          {{ $t("department_name") }}
                        </option>
                        <option
                          v-for="(city, index) in cities"
                          :key="index"
                          :value="city.id"
                        >
                          {{ city.title }}
                        </option>
                      </select>
                      <span v-if="!join.city_id" class="text">{{
                        $t("department_name")
                      }}</span>
                      <!-- department -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- finish input group -->
              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("write_address_en") }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="join.adress"
                  @input="validateAddressEn"
                  id="address_en"
                  required
                />
                <span v-if="addressEnError" class="error">{{
                  addressEnError
                }}</span>
              </div>

              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("write_address_ar") }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  @input="validateAddressAr"
                  id="address_ar"
                  v-model="join.adress2"
                />
                <span v-if="addressArError" class="error">{{
                  addressArError
                }}</span>
              </div>

              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label"> {{ $t("sort_en") }}</label>
                <textarea
                  class="form-control"
                  :placeholder="$t('write_here')"
                  @input="validateAboutEn"
                  id="about_en"
                  v-model="join.about_en"
                ></textarea>
                <span v-if="aboutEnError" class="error">{{
                  aboutEnError
                }}</span>
              </div>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label"> {{ $t("sort_ar") }}</label>
                <textarea
                  class="form-control"
                  :placeholder="$t('write_here')"
                  @input="validateAboutAr"
                  id="about_ar"
                  v-model="join.about_ar"
                ></textarea>
                <span v-if="aboutArError" class="error">{{
                  aboutArError
                }}</span>
              </div>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label"> {{ $t("write_points") }}</label>
                <!-- <option selected>{{ $t("write_points") }}</option> -->
                <Multiselect
                  v-model="join.law_service_ids"
                  :options="services.map((service) => service.id)"
                  :custom-label="
                    (opt) => services.find((x) => x.id == opt).title
                  "
                  :multiple="true"
                  preserve-search="true"
                  :clear-on-select="true"
                  :placeholder="$t('service_offer')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
                <!-- <select
                  class="form-select"
                  aria-label="Default select example"
                  id="fruits"
                  v-model="join.law_service_ids"
                  multiple
                >
                  <option
                    v-for="(country, index) in countries"
                    :key="index"
                    :value="country.id"
                  >
                    {{ country.title }}
                  </option>
                </select> -->
              </div>
              <!-- finish input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_wats") }}
                </label>
                <input
                  type="url"
                  class="form-control"
                  v-model="join.whatsapp"
                />
              </div>

              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_twiit") }}
                </label>
                <input type="url" class="form-control" v-model="join.twitter" />
              </div>

              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_face") }}
                </label>
                <input
                  type="url"
                  class="form-control"
                  v-model="join.facebook"
                />
              </div>

              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_link") }}
                </label>
                <input
                  type="url"
                  class="form-control"
                  v-model="join.linkedin"
                />
              </div>

              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_inst") }}
                </label>
                <input
                  type="url"
                  class="form-control"
                  v-model="join.instagram"
                />
              </div>
              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_tiktok") }}
                </label>
                <input type="url" class="form-control" v-model="join.tiktok" />
              </div>
              <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social_youtube") }}
                </label>
                <input type="url" class="form-control" v-model="join.youtube" />
              </div>
              <!-- social append link -->
              <!-- <div class="input-group">
                <label class="form-label">
                  {{ $t("enter_social") }}
                </label>
                <input type="url" class="form-control" v-model="join.social" />
              </div>
              <ul class="social_menu">
                <li v-for="(link, index) in join.addSocial" :key="index">
                  {{ link }}
                  <span @click="removeLink(index)"
                    ><i class="bi bi-x-circle"></i
                  ></span>
                </li>
              </ul>
              <span @click="addLinks()" class="btn add">
                {{ $t("add_links") }} <i class="bi bi-plus"></i
              ></span> -->
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <div class="button">
                  <button
                    class="next first btn"
                    type="submit"
                    :disabled="isValid"
                    @click.prevent="nextStep"
                  >
                    {{ $t("cont") }}
                  </button>
                </div>
              </div>
              <!-- finish input group -->
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="img">
              <img src="../assets/image/contract_2.png" alt="contract" />
            </div>
          </div>
        </div>
        <!-- finish first stage -->

        <!-- start second stage -->
        <div v-else-if="step === 2">
          <div class="second_step">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-12">
                  <faq />
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  <div class="packages">
                    <div class="input-group">
                      <label
                        v-for="(packag, index) in packages"
                        :key="index"
                        :for="index"
                        :class="{ select_class: selectedOption === index }"
                      >
                        <input
                          @change="selectOption(index)"
                          :class="{ selected: selectedOption === index }"
                          class="form-control"
                          v-model="join.package_id"
                          type="radio"
                          :value="packag.id"
                          name="ds"
                        />
                        <div>
                          <span class="circle"></span>
                          <h4>{{ packag.title }}</h4>
                          <h6>{{ packag.price }}</h6>
                          <p v-html="packag.text"></p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group submit">
            <button class="btn next" @click.prevent="prevStep">
              {{ $t("back") }}
            </button>
            <button class="btn first" @click.prevent="submitForm">
              {{ $t("join_now") }}
            </button>
          </div>
        </div>
        <!-- start second stage -->
      </form>
    </div>
    <!-- <second-step /> -->
  </section>
</template>

<script>
import Swal from "sweetalert2";
import JoinsButtons from "@/components/join/joinsButtons/index.vue";
import axios from "axios";
import faq from "../components/join/faq.vue";
import { joinData } from "@/api/join/packages.js";
import Multiselect from "vue-multiselect";

// import Form from "@/components/join/form.vue";
export default {
  components: {
    faq,
    Multiselect,
    JoinsButtons,
  },
  data() {
    return {
      step: 1,
      join: {
        name: "",
        phone: "",
        email: "",
        adress: "",
        adress2: "",
        uploadedImageName: "",
        about_ar: "",
        about_en: "",
        law_service_ids: [],
        country_id: "",
        city_id: "",
        state_id: "",
        package_id: "",
        social: "",
        whatsapp: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        instagram: "",
        tiktok: "",
        youtube: "",
        addSocial: [],
      },
      fileData: {
        name: "",
      },
      packages: {},
      Option: [],
      selectedOption: "index",
      services: [],
      countries: [],
      cities: [],
      states: [],

      nameFile: "",
      uploadedImage: null,
      fileUploaded: "",
      errors: {
        name: "",
        email: "",
        phone: "",
        aboutEnError: "",
        aboutArError: "",
        addressEnError: "",
        addressArError: "",
      },
    };
  },
  computed: {
    isValid() {
      return (
        this.addressEnError === "" ||
        this.addressArError === "" ||
        this.aboutEnError === "" ||
        this.aboutArError === ""
      );
    },
  },
  methods: {
    //upload file
    handleFileUpload(event) {
      this.fileData = event.target.files[0];
      // console.log(this.fileData);
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.fileUploaded = reader.result);
    },

    //slected package
    selectOption(index) {
      this.selectedOption = index;
    },
    //fetch country data
    fetch_country_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.heroData);
      });
    },
    //fetch city data
    fetchState(e) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let country = { country_id: e.target.value };
      axios.post("/fetch_states_by_country_id", country).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.states = data.data.data;
        }
      });
    },
    //fetch statment data
    fetchDepartment(x) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let state = { state_id: x.target.value };
      axios.post("/fetch_cities_by_state_id", state).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.cities = data.data.data;
          // console.log(this.department);
        }
      });
    },

    fetch_law_services() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_law_services").then(({ data }) => {
        this.services = data.data;
        // console.log(this.services);
      });
    },

    // uploadFiles() {
    //   const uploadedFiles = this.$refs.fileInput.files;
    //   for (let i = 0; i < uploadedFiles.length; i++) {
    //     this.join.files.push(uploadedFiles[i]);
    //   }
    //   const formData = new FormData();
    //   for (let i = 0; i < this.join.files.length; i++) {
    //     formData.append("files", this.join.files[i]);
    //   }
    // },
    removeFile(index) {
      this.join.files.splice(index, 1);
    },
    //add links to social
    addLinks() {
      let x = this.join.social;
      console.log(x);
      this.join.addSocial.push(x);
      x = "";
    },
    //remove link from social
    removeLink(index) {
      this.join.addSocial.splice(index, 1);
    },
    //next step for form wizard
    nextStep() {
      if (this.step === 1) {
        this.validateName();
        this.validateEmail();
        this.validatePhone();
        this.validateAboutEn();
        this.validateAboutAr();
        this.validateAddressEn();
        this.validateAddressAr();
        // console.log("test1");
      } else if (this.step === 2) {
        // console.log("text");
      }
      if (
        !this.errors.name &&
        !this.errors.email &&
        !this.errors.phone &&
        !this.aboutEnError &&
        !this.aboutArError &&
        !this.addressEnError &&
        !this.addressArError
      ) {
        this.step++;
      }
    },
    //prev step for form wizard
    prevStep() {
      this.step--;
    },
    validateName() {
      if (!this.join.name) {
        this.errors.name = this.$t("field is required");
      } else {
        this.errors.name = "";
      }
    },
    validateEmail() {
      if (!this.join.email) {
        this.errors.email = this.$t("field is required");
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.join.email)
      ) {
        this.errors.email = this.$t("Email is invalid");
      } else {
        this.errors.email = "";
      }
    },
    validatePhone() {
      if (!this.join.phone) {
        this.errors.phone = this.$t("field is required");
      } else if (!/^\d{9,13}$/.test(this.join.phone)) {
        this.errors.phone = this.$t("Phone is invalid");
      } else {
        this.errors.phone = "";
      }
    },

    //vildate about english
    validateAboutEn() {
      if (!this.join.about_en) {
        this.aboutEnError = this.$t("field is required");
      } else if (!/^[a-zA-Z ]+$/.test(this.join.about_en)) {
        this.aboutEnError = this.$t("English characters only");
      } else {
        this.aboutEnError = "";
      }
    },
    //vildate about arabic
    validateAboutAr() {
      if (!this.join.about_ar) {
        this.aboutArError = this.$t("field is required");
      } else if (!/^[ء-ي٠-٩ ]+$/.test(this.join.about_ar)) {
        this.aboutArError = "arabic characters only";
      } else {
        this.aboutArError = "";
      }
    },

    //vildate adress english
    validateAddressEn() {
      if (!this.join.adress) {
        this.addressEnError = this.$t("field is required");
      } else if (!/^[a-zA-Z ]+$/.test(this.join.adress)) {
        this.addressEnError = "English characters only";
      } else {
        this.addressEnError = "";
      }
    },
    //vildate adress arabic
    validateAddressAr() {
      if (!this.join.adress2) {
        this.addressArError = this.$t("field is required");
      } else if (!/^[ء-ي٠-٩ ]+$/.test(this.join.adress2)) {
        this.addressArError = "Arabic characters only";
      } else {
        this.addressArError = "";
      }
    },
    submitForm() {
      this.validateName();
      this.validateEmail();
      this.validatePhone();

      const formData = new FormData();
      formData.append("name", this.join.name);
      formData.append("email", this.join.email);
      formData.append("phone", this.join.phone);
      formData.append("address_en", this.join.adress);
      formData.append("address_ar", this.join.adress2);
      formData.append("country_id", this.join.country_id);
      formData.append("state_id", this.join.state_id);
      formData.append("city_id", this.join.city_id);
      formData.append("about_en", this.join.about_en);
      formData.append("about_ar", this.join.about_ar);
      formData.append("whatsapp", this.join.whatsapp);
      formData.append("twitter", this.join.twitter);
      formData.append("facebook", this.join.facebook);
      formData.append("linkedin", this.join.linkedin);
      formData.append("instagram", this.join.instagram);
      formData.append("tiktok", this.join.tiktok);
      formData.append("youtube", this.join.youtube);
      formData.append("license_image", this.fileData);
      for (let item = 0; item < this.join.law_service_ids.length; item++) {
        formData.append(
          `law_service_ids[${item}]`,
          this.join.law_service_ids[item]
        );
      }
      formData.append("package_id", this.join.package_id);

      axios
        .post("/store_joiners", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    joinData().then((response) => {
      this.packages = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
  created() {
    this.fetch_country_data();
    this.fetch_law_services();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
